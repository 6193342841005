import React from "react";

import ThankYou from "../img/thankyou.png";

const DISCORD_URL = "https://discord.gg/TZgUwZzpZx";

export default function Anniversary()
{
    return <>
        <h1>Early Access first anniversary</h1>
        <p>
            November 21st, 2024 marks the first anniversary of Polymino being
            released on Steam Early Access! It's hard to believe how far the
            game has come in that time, including features which I previously
            saw as pipe dreams like online multiplayer.
        </p>
        <p>
            Polymino's growth wouldn't have been possible without all of the
            suggestions from members of the <a href={DISCORD_URL}>
                Discord server
            </a>, and the number one thing that's kept me motivated all this
            time is everyone who's played Polymino, spread the word, and given
            me your feedback.
        </p>
        <img src={ThankYou} alt="THANK YOU!!" style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: "24px",
        }}/>
    </>;
}
