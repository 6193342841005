import React from "react";
import {Link, useParams} from "react-router-dom";
import wikiCategoryName from "./wikiCategoryName";

/**
 * @param {Object} props
 * @param {string} props.category
 * @param {string} props.page
 * @param {boolean} [props.showCategory]
 * @param {boolean} [props.top]
 */
export default function WikiLink({
    category,
    page,
    showCategory = false,
    top = false,
})
{
    const {wikiCat, wikiPage} = useParams();
    // Don't make it a link if we're on the page
    if (category.toLowerCase() === wikiCat?.toLowerCase()
        && page.toLowerCase() === wikiPage?.toLowerCase())
    {
        return page;
    }
    else
    {
        return <Link to={`/wiki/${category}/${page}${top ? "#top" : ""}`}>
            {showCategory && wikiCategoryName(category) + " - "}{page}
        </Link>;
    }
}
