import React from "react";
import Navbar  from "../Navbar";
import Footer from "../Footer";
import Webring from "../Webring";

import SocialLinks from "./SocialLinks";
import Anniversary from "./Anniversary";
import OSTBTS from "./OSTBTS";

export default function News()
{
    return <div>
        <Navbar active="news"/>
        <div id="content-root">
            <div id="main-text">
                <SocialLinks/>
                <Anniversary/>
                <OSTBTS/>
            </div>
        </div>
        <Webring/>
        <Footer/>
    </div>;
}
