/**
 * @param {string} s
 * @returns {string}
 */
export function capitalize(s)
{
    return s[0].toUpperCase() + s.substring(1).toLowerCase();
}

/**
 * @param {string} s
 * @returns {string}
 */
export default function toTitleCase(s)
{
    return s
        .split(' ')
        .map(s => s.length > 2 && s.toLowerCase() !== "the" ? capitalize(s) : s)
        .join(' ');
};
