import React from "react";
import toTitleCase from "../toTitleCase";

import "./wiki-search.css";

/**
 * @typedef {Object} Match
 * @prop {string} route
 * @prop {string} category
 * @prop {string} page
 * @prop {number} andScoreTitle
 * @prop {number} andScore
 * @prop {number} orScore
 * @prop {string | null} sectionMatch
 */

/**
 * @param {Object} props
 * @param {Match} props.match
 * @param {number} props.querySize
 * @param {number} props.andScoreTotal
 */
export default function SearchResultItem({match, querySize, andScoreTotal})
{
    if (match.sectionMatch)
        return <SectionMatchItem match={match}/>;
    return <li className="wikiSearchItem">
        <a href={match.route}>
            <div>
                {match.category}: {match.page}
            </div>
            <div className="resultReason">
                {getExplanation(match, querySize, andScoreTotal)}
            </div>
        </a>
    </li>;
}

/**
 * @param {Object} props
 * @param {Match} props.match
 */
function SectionMatchItem({match})
{
    const sectionLink =
        `${match.route}#${match.sectionMatch.section.replaceAll(' ', '-')}`;
    return <li className="wikiSectionMatchItem">
        <a href={match.route}>
            <div>
                {match.category}: {match.page}
            </div>
        </a>
        <a href={sectionLink}>
            <div className="resultSection">
                Matching category: {toTitleCase(match.sectionMatch.section)}
            </div>
        </a>
    </li>;
}

/**
 * @param {Match} match
 * @param {number} querySize
 * @param {number} andScoreTotal
 */
function getExplanation(match, querySize, andScoreTotal)
{
    const confidence = match.andScore / andScoreTotal;
    const conPercent = (confidence * 100).toFixed(1) + "%";
    if (match.andScoreTitle > 0)
    {
        return "Matching title";
    }
    else if (querySize === 1)
    {
        return "Text matches: " + match.orScore;
    }
    // Even though we don't give priority when confidence is less than
    // AND_MIN_CONFIDENCE, we should still tell the user all terms were found.
    else if (confidence > 0)
    {
        return "All terms found (confidence: " + conPercent + ")";
    }
    else
    {
        return "At least one term found, " + match.orScore + " matches total";
    }
}
