import React from "react";
import {useParams} from "react-router-dom";
import AllPages from "./pages/all_pages.json"

import "../index.css";

const ALL_PAGES = AllPages.map(path => path.split('/'));

export default function Wiki404()
{
    const {wikiCat, wikiPage} = useParams();
    const result = ALL_PAGES.find(([cat, page]) =>
        wikiCat?.toLowerCase() === cat && wikiPage?.toLowerCase() === page
    );
    return result === undefined ?
        <div id="content-root">
            <div id="main-text">
                <p>The wiki page you are looking for does not exist.</p>
                <p>
                    <a href="/wiki">Wiki homepage</a>
                    &nbsp;/&nbsp;
                    <a href="/">Site homepage</a>
                </p>
            </div>
        </div>
        :
        null;
}
