import React from "react";

import {
    junkModDetails,
    junkTransferDetails,
    bombAbilityDetails,
    transferBombDetails,
    queueShapeModDetails,
    onShatterGlobalDetails,
    junkSpawnDetails,
    timedAbilityDetails,
    transferSpreeDetails,
} from "./AbilityToStr";

/**
 * @returns {[[number, number, number], number]}
 */
function getMeterLevels(loadout)
{
    const levels = loadout.levels ?? [400, 400, 400];
    const bombCost = loadout.bomb_cost ?? 200;

    return [levels, bombCost];
}

export default function Abilities({loadout})
{
    // Need to create a copy so re-renders don't create incorrect calculations
    const levels = Array.from(getMeterLevels(loadout)[0]);
    levels[1] += levels[0];
    levels[2] += levels[1];
    return <>{[0, 1, 2].map(i => <Ability
        key={i}
        level={i + 1}
        ability={loadout.abilities[i]}
        cost={levels[i]}
    />)}</>;
}

const ABILITY_BUILDERS = new Map([
    ["junk_mod", junkMod],
    ["junk_transfer", junkTransfer],
    ["bomb", bombAbility],
    ["transfer_bomb", transferBomb],
    ["queue_shapes", queueShapeMod],
    ["on_shatter", onShatter],
    ["junk_spawn", junkSpawn],
    ["timed", timedAbility],
    ["transfer_spree", transferSpree],
]);

function Ability({ability, cost, level})
{
    return ABILITY_BUILDERS.get(ability.type)(ability, cost, level);
}

function AbilityHeader(abName, cost, level, desc)
{
    const ulStyle = {
        fontWeight: "normal",
        listStyleType: "none",
        paddingLeft: 20
    };

    const h4Style = {
        marginTop: 15,
        marginBottom: 15
    };

    return <h4 style={h4Style}>
        {level}. {abName} (cost: {cost})
        <ul style={ulStyle}><li>- {desc}</li></ul>
    </h4>;
}

function AbilitySummary(ability)
{
    return ability.summary && <>Summary: <i>{ability.summary}</i><br/></>;
}

function junkMod(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Junk Modifier", cost, level, "Applies one or more effects to one or more junk pieces.")}
        {AbilitySummary(ability)}
        {junkModDetails(ability)}
    </div>;
}

function junkTransfer(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Junk Transfer", cost, level, "Transfers pieces from your board to each opponent's board.")}
        {AbilitySummary(ability)}
        {junkTransferDetails(ability)}
    </div>;
}

function bombAbility(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Bomb", cost, level, "A bomb which can have unique shapes.")}
        {AbilitySummary(ability)}
        {bombAbilityDetails(ability)}
    </div>;
}

function transferBomb(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Transfer Bomb", cost, level, "A bomb which transfers junk pieces instead of destroying them.")}
        {AbilitySummary(ability)}
        {transferBombDetails(ability)}
    </div>;
}

function queueShapeMod(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Queue Shape Modifier", cost, level, "Modifies the shapes of junk pieces in the queue.")}
        {AbilitySummary(ability)}
        {queueShapeModDetails(ability)}
    </div>;
}

function onShatter(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Global On-shatter", cost, level, "Any junk destroyed for a limited time will have an on-shatter effect.")}
        {AbilitySummary(ability)}
        {onShatterGlobalDetails(ability)}
    </div>;
}

function junkSpawn(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Junk Spawn", cost, level, "Immediately spawns one or more junk pieces.")}
        {AbilitySummary(ability)}
        {junkSpawnDetails(ability)}
    </div>;
}

function timedAbility(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Timed Effect", cost, level, "A miscellaneous timed effect.")}
        {AbilitySummary(ability)}
        {timedAbilityDetails(ability)}
    </div>;
}

function transferSpree(ability, cost, level)
{
    const className = `ability level${level}`;
    return <div className={className}>
        {AbilityHeader("Transfer Spree", cost, level, "For a limited time, junk hit by projectiles will be transferred instead of destroyed.")}
        {AbilitySummary(ability)}
        {transferSpreeDetails(ability)}
    </div>;
}
