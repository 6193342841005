import {useParams, useSearchParams} from "react-router-dom";
import wikiCategoryName from "./wikiCategoryName";
import wikiPages from "./pages/all_pages.json";
import toTitleCase from "../toTitleCase";

function redirect(cat, page)
{
    window.location = `/wiki/${cat}/${page}`;
}

/**
 * Redirects to the main page for the category in the current URL.
 */
export default function CategoryMainPage()
{
    const {wikiCat} = useParams();
    redirect(wikiCat.toLowerCase(), wikiCategoryName(wikiCat));
}

/**
* Redirects "basics" and category names to the main page for that category.
*/
export function useWikiRedirects()
{
    const {wikiCat, wikiPage} = useParams();
    const page = wikiPage?.toLowerCase();
    // Redirect on:
    // - "[Bb]asics" (old name of each main page)
    // - the mode name without the "mode" suffix (which happens to be equal to
    //   the category name)
    // - "Junk Attack" with erroneous "mode" suffix
    if (page === "basics" || page === wikiCat
        || (wikiCat === "junkatk" && page === "junk attack mode"))
    {
        CategoryMainPage();
    }
}

/**
 * If the search query exactly matches the name of a page, redirect to it.
 */
export function useSearchRedirects()
{
    const [params] = useSearchParams();
    const query = params.get("query")?.toLowerCase();
    if (!query)
        return;

    // if the search matches a page name exactly, go there
    for (const route of wikiPages)
    {
        const [cat, page] = route.split('/');
        if (query === page || query + " mode" === page || query === page + " mode")
        {
            redirect(cat, toTitleCase(page));
            return;
        }
    }
}
