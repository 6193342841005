import React from "react";

import "./news.css";

const DISCORD_URL = "https://discord.gg/TZgUwZzpZx";

export default function SocialLinks()
{
    const [show, setShow] = React.useState(true);

    return <div className={`social-links social-links-${show ? "show" : "hide"}`}>
        <div className="collapse-header" onClick={() => setShow(!show)}>
            <div>
                <h1>How to follow development</h1>
            </div>
            <div>
                <button>
                    {show ? "Hide" : "Show"}
                </button>
            </div>
        </div>
        <div className="social-content">
            <h2>Steam Community</h2>
            <p>
                For detailed patch notes, check out the <a
                    href="https://steamcommunity.com/app/2537550"
                >
                    Steam Community page
                </a> for Polymino!
            </p>
            <h2>Discord</h2>
            <p>
                Polymino's <a href={DISCORD_URL}>Discord server</a> features the
                latest updates about what's being worked on, and is the best
                place to keep up-to-date with development.
                I love to discuss players' feedback and ideas there, many
                of which quickly end up in the game!
            </p>
            <h2>YouTube</h2>
            <p>
                While they usually aren't development updates,
                I sometimes upload videos about Polymino to my <a
                    href="https://www.youtube.com/@welsh_"
                >YouTube channel.</a> For entertaining content
                related to the game, and any
                big announcements which I want to reach new players,
                YouTube is the place to go!
            </p>
        </div>
    </div>;
}
