import React from "react";
import Navbar from "./Navbar";

import "./index.css";

export default function FourOhFour()
{
    return <div>
        <Navbar/>
        <div id="content-root">
            <div id="main-text">
                <h1>Not Found</h1>
                <p>The page you are looking for doesn't exist.</p>
                <p><a href="/">Get me out of here!</a></p>
            </div>
        </div>
    </div>;
}
