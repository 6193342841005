import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import FourOhFour from "./404";
import Home from "./Home";
import News from "./news/News";
import WikiContainer from "./wiki/WikiContainer";
import WikiLanding from "./wiki/Landing";
import WikiPage from "./wiki/Page";
import CategoryMainPage from "./wiki/PageRedirects";
import SearchResults from "./wiki/SearchResults";
import Downloads from "./Downloads";

export default function App()
{
    return <Router>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/news" element={<News/>}/>
            <Route path="/wiki" element={<WikiContainer/>}>
                <Route index element={<WikiLanding/>}/>
                <Route path="/wiki/search" element={<SearchResults/>}/>
                <Route path="/wiki/:wikiCat/:wikiPage" element={<WikiPage/>}/>
                <Route path="/wiki/:wikiCat" element={<CategoryMainPage/>}/>
            </Route>
            <Route path="/downloads" element={<Downloads/>}/>
            <Route path="*" element={<FourOhFour/>}/>
        </Routes>
    </Router>;
}
