import React from "react";
import Navbar  from "./Navbar";
import Footer from "./Footer";
import Webring from "./Webring";

import AnimatedLogo from "./AnimatedLogo";

import {Demo1, Demo2, Demo3} from "./img/animloader";

import "./home.css";

export default function Home()
{
    return <div>
        <Navbar active="home"/>
        <AnimatedLogo/>
        <div id="content-root">
            <div id="gameSummary">
                <h1>About</h1>
                <p>
                    Polymino is an intense puzzle game where your goal is to
                    prevent your board from filling with blocks and the large
                    junk pieces that are continually dropped at the top.
                    Match three blocks of the same color to launch projectiles
                    at junk from afar, shattering it into more blocks which
                    you can use to make more matches and destroy more junk.
                </p>
                <Demo1/>
                <h2>Get involved!</h2>
                <p>
                    Polymino is currently available on <a
                        href="https://store.steampowered.com/app/2537550/Polymino/"
                        target="_blank" rel="noreferrer"
                    >
                        Steam Early Access
                    </a>, and feedback from players is making a huge difference.
                    If you would like to help drive development by playing the
                    game, reporting issues, suggesting your ideas and providing
                    any other feedback, check it out on Steam and join the <a
                        href="https://discord.gg/TZgUwZzpZx"
                        target="_blank" rel="noreferrer"
                    >
                        official Discord server!
                    </a>
                </p>
                <Demo3/>
                <h2>History</h2>
                <p>
                    Polymino started as a passion project while I was a student;
                    I've always loved low-level computing and retro video games,
                    so to explore those areas, I originally created Polymino as
                    a game for the Sega Genesis.
                    However, as development progressed, the enjoyment I derived
                    from working on Polymino became more about the game and less
                    about the hardware, and I felt that Polymino could stand as
                    a fun game in its own right.
                </p>
                <p>
                    That's why I decided to completely remake the game for
                    modern PCs, freeing it from hardware limitations so it could
                    achieve its full potential.
                    Since publishing the game on Steam Early Access, feedback
                    from players has allowed Polymino to soar above my initial
                    vision for the project, and there's still more content and
                    improvements to come!
                </p>
                <Demo2/>
                <h2>More details</h2>
                <p>
                    Polymino's biggest source of inspiration is <i>Tetris
                    Attack</i> (also known as <i>Panel de Pon</i> or <i>Puzzle
                    League</i>), but has many unique aspects:
                </p>
                <ul>
                    <li>
                        Junk pieces appear in all game modes and serve as both
                        a threat and a resource, being the only thing added to
                        the board over time.
                        {/*
                        On the one hand, you lose if you don't have room for the
                        next junk piece.
                        On the other hand, once you exhaust the starting blocks
                        on your board, you must depend on junk as your only
                        source of new blocks.
                        */}
                    </li>
                    <li>
                        Instead of destroying junk by making adjacent matches,
                        matches launch projectiles which can destroy junk at any
                        distance. 
                    </li>
                    <li>
                        Junk can be gray, or it can be any of the same colors as
                        regular blocks. Gray junk can be destroyed by any
                        projectiles, but colored junk can only be destroyed by
                        projectiles of the same color (created by matching
                        blocks with that color).
                    </li>
                    <li>
                        Instead of a combo being how many matches you can make
                        in a single move,
                        combos in Polymino are maintained across moves,
                        only resetting when a move doesn't make any matches.
                        However, this means making a match won't pause the timer
                        before the next junk piece drops.
                    </li>
                    <li>
                        Last but not least, Polymino features bombs which you
                        gain by earning points.
                        Bombs wipe out a large area of the board, taking out
                        blocks and junk pieces.
                        Your combo and difficulty <i>both</i> multiply your
                        score, and using a bomb while both are high can earn
                        enough points to gain more than one bomb back.
                        In fact, at the highest difficulty, this becomes the
                        only feasible way to survive long-term.
                    </li>
                </ul>
            </div>
        </div>
        <Webring/>
        <Footer/>
    </div>;
}
