export default function wikiCategoryName(cat)
{
    switch (cat.toLowerCase())
    {
        case "classic":
            return "Classic Mode";
        case "junkatk":
            return "Junk Attack";
        case "puzzle":
            return "Puzzle Mode";
        case "gauntlet":
            return "Gauntlet Mode";
        default:
            return "(unknown)";
    }
    // note: if you add a new category, update PageRedirects.js too!
}
