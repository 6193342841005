import React from "react";
import Navbar  from "./Navbar";
import Footer from "./Footer";
import Webring from "./Webring";

export default function Downloads()
{
    return <div>
        <Navbar active="downloads"/>
        <div id="content-root">
            <div id="main-text">
                <h1>Steam</h1>
                <p>
                    The PC version of Polymino can be purchased on
                    Steam <a href="https://store.steampowered.com/app/2537550/Polymino/">
                    here.</a>
                </p>
                <h2>Sega Genesis version</h2>
                <p>
                    As described on this site's <a href="/">home page,
                    </a> Polymino's first
                    incarnation was a homebrew Sega Genesis game I made in 2022.
                    <b> Be warned:</b> the Genesis version is playable, but it's
                    unpolished and buggy in many areas, and the gameplay isn't
                    nearly as refined as the PC version.
                </p>
                <p>
                    I include the Genesis version here for anybody curious about
                    what Polymino was like on the Genesis, and as a
                    demonstration of how far it's come.
                    If you haven't played the PC version, <b>please know
                    that the quality of the Genesis version does not reflect
                    that of the PC version.</b>
                </p>
                <div style={{
                    width: "fit-content",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}>
                    <a href="/polymino.bin" download="polymino.bin">
                        Download the ROM
                    </a>
                </div>
                <p>
                    To play it, run the downloaded file (<code>polymino.bin
                    </code>) in the Sega Genesis emulator of your choice.
                    <br/>
                    <span style={{color: "grey"}}>
                        (If you run it on real hardware, I would love to hear
                        about it! Join the Discord server and post some pics!)
                    </span>
                </p>
            </div>
        </div>
        <Webring/>
        <Footer/>
    </div>;
}
